










































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  JamModule as jamstore,
} from "@/store/modules";

@Component({
  components: {
    Input,
    Button,
    Form,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  loading: boolean = false;
  async created() {
    let vm = this;
    await jamstore.getJam();
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get trafficjam() {
    return jamstore.jam;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();

    await jamstore.createJam();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.Root);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.Root);
  }

  mounted() {}
  updated() {}
}
