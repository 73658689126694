var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Traffic Jam Configuration")]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.Root}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Speed'),
                    'is-required': _vm.isFieldRequired('Speed'),
                  },attrs:{"label":"Min. Speed"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Speed","name":"Speed"},model:{value:(_vm.trafficjam.speed),callback:function ($$v) {_vm.$set(_vm.trafficjam, "speed", $$v)},expression:"trafficjam.speed"}}),(_vm.errors.has('Speed'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Speed")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Vehicle'),
                    'is-required': _vm.isFieldRequired('Vehicle'),
                  },attrs:{"label":"No of Vehicles"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"No. of Vehicle","name":"Vehicle"},model:{value:(_vm.trafficjam.numberOfVehicle),callback:function ($$v) {_vm.$set(_vm.trafficjam, "numberOfVehicle", $$v)},expression:"trafficjam.numberOfVehicle"}}),(_vm.errors.has('Vehicle'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Time'),
                    'is-required': _vm.isFieldRequired('Time'),
                  },attrs:{"label":"Min. Speed Duration"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Stoppage Time","name":"Time"},model:{value:(_vm.trafficjam.stoppageTime),callback:function ($$v) {_vm.$set(_vm.trafficjam, "stoppageTime", $$v)},expression:"trafficjam.stoppageTime"}}),(_vm.errors.has('Time'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Time")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }